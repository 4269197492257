import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

// Instructions ---->
// Replace configPlaceholder with environment.firebase
// import { environment } from '../environments/environment';
// import configPlaceholder from './env';
import { ChatComponent } from './chat/chat.component';
import { HomeComponent } from './home/home.component';
import { environment } from 'src/environments/environment';

const config = {
    apiKey: 'AIzaSyAr8D3pe7tBzzpzP8pCJvAtTuGPUnLeXtA',
    authDomain: 'zeus-chat-f77e7.firebaseapp.com',
    databaseURL: 'https://zeus-chat-f77e7.firebaseio.com',
    projectId: 'zeus-chat-f77e7',
    storageBucket: 'zeus-chat-f77e7.appspot.com',
    messagingSenderId: '818784102422',
    appId: '1:818784102422:web:2b4e2a627381eaba'
};

@NgModule({
  declarations: [AppComponent, ChatComponent, HomeComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireStorageModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {}
